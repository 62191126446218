import { ChangeDetectionStrategy, Component, InputSignal, OutputEmitterRef, input, output } from '@angular/core';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { RouterLink } from '@angular/router';

import { HeaderElementDirective } from '@core/shared/util';
import {
  ExportButtonComponent,
  InfiniteScrollComponent,
  OverviewModule,
  PagedDataSource,
  RootModuleNavComponent,
} from '@core/ui';
import { PlatformSupplierBasic } from '@mp/supplier-management/common/domain';
import { SupplierIconComponent } from '@mp/supplier-management/common/ui';

type FetchParams = { searchTerm: string };

@Component({
  selector: 'mpsm-platform-supplier-list',
  standalone: true,
  templateUrl: './platform-supplier-list.component.html',
  styleUrls: ['./platform-supplier-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterLink,

    MatLegacyButtonModule,
    MatLegacyMenuModule,

    ExportButtonComponent,
    HeaderElementDirective,
    InfiniteScrollComponent,
    SupplierIconComponent,
    OverviewModule,
    RootModuleNavComponent,
  ],
})
export class PlatformSupplierListComponent {
  readonly resultCount: InputSignal<number> = input.required<number>();
  readonly isProcessingExport: InputSignal<boolean> = input.required<boolean>();
  readonly dataSource: InputSignal<PagedDataSource<PlatformSupplierBasic, FetchParams>> =
    input.required<PagedDataSource<PlatformSupplierBasic, FetchParams>>();

  readonly searched: OutputEmitterRef<string> = output<string>();
  readonly exportClicked: OutputEmitterRef<void> = output<void>();

  onSearch(searchTerm: string): void {
    this.searched.emit(searchTerm);
  }

  onExportAll(): void {
    this.exportClicked.emit();
  }
}
